import {
  SET_IS_UPLOADING,
  SET_UPLOADING_ERROR
} from '../Actions/actionTypes'

const initialState = {
  isUploading: [false, false, false],
  uploadingError: undefined
};

export default function map(state = initialState, action) {
  switch (action.type) {

    case SET_IS_UPLOADING:
      let newIsUploading = [...state.isUploading];
      newIsUploading[action.index] = action.value;
      return {
        ...state,
        isUploading: newIsUploading,
      };
    case SET_UPLOADING_ERROR:
      return {
        ...state,
        uploadingError: action.uploadingError
      };
    default:
      return state;
  }
}
