import URLSearchParams from 'url-search-params';
import {
  AUTH_SET_IS_LOGGING_IN,
  AUTH_SET_ACCESS_TOKEN,
  AUTH_SET_USER,
  AUTH_SET_IS_INITIALIZED,
  AUTH_SET_IS_LOGIN_DIALOG_OPEN,
  AUTH_SET_LOGIN_ERROR
} from './actionTypes'
import ReactGA from "react-ga4";


export function googleLogin(access_token, rememberMe) {
  return (dispatch) => {
    dispatch(setIsLoggingIn(true));

    var myHeaders = new Headers();
    myHeaders.append('access_token', access_token);

    var body = new URLSearchParams();
    body.set("rememberMe", rememberMe);

    var fetchParams = {
      method: 'POST',
      headers: myHeaders,
      credentials: 'same-origin',
      body
    };

    fetch(`${process.env.REACT_APP_API_ADDRESS}/api/auth/login/google`, fetchParams)
      .then(res => {
        if (!res.ok) {
          dispatch(setAccessToken(null));
          dispatch(setUser(null));
          throw Error(`Failed to login`);
        }
        return res.json();
      })
      .then(res => {
        console.log(`Logged in.`);
        dispatch(setAccessToken(res.token));
        dispatch(setUser(res.user));
        ReactGA.set({ userId: res.user.id });
        dispatch(setIsLoggingIn(false));
      })
      .catch((err) => {
        console.log(`Error: ${err}`);
        dispatch(setIsLoggingIn(false));
      });
  }
}

export function login(email, password, rememberMe) {
  return async (dispatch) => {
    try {
      dispatch(setIsLoggingIn(true));

      var data = new URLSearchParams();
      data.set("email", email);
      data.set("password", password);
      data.set("rememberMe", rememberMe);

      let fetchParams = {
        method: "POST",
        body: data
      };

      let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/auth/login/local`, fetchParams);

      if (res.ok) {
        console.log(`Logged in.`);
        let resJson = await res.json();
        dispatch(setAccessToken(resJson.token));
        dispatch(setUser(resJson.user));
        ReactGA.set({ userId: resJson.user.id });
        dispatch(hideLoginDialog());
        console.error(`Successful login of user ${resJson.user.profile.name}`);
        return resJson.user;
      } else {
        dispatch(setAccessToken(null));
        dispatch(setUser(null));

        if (res.status === 422) {
          let resJson = await res.json();
          if (resJson.errors[0].param === 'email') {
            return dispatch(setLoginError("Invalid email"));
          }
          if (resJson.errors[0].param === 'password') {
            return dispatch(setLoginError("Password needs to be at least 4 characters long"));
          }
        }
        if (res.status === 500) {
          dispatch(setLoginError('Logging in is currently unavailable. Please try again later'));
        } else {
          dispatch(setLoginError(`Wrong email or password`));
        }
      }
    } catch (err) {
      console.error(`Login error: ${err}`);
      dispatch(setLoginError('Logging in is currently unavailable. PLease refresh page'));
    } finally {
      dispatch(setIsLoggingIn(false));
    }
  }
}

export function signUp(email, password, rememberMe) {
  return async (dispatch) => {
    try {
      dispatch(setIsLoggingIn(true));

      var data = new URLSearchParams();
      data.set("email", email);
      data.set("password", password);
      data.set("rememberMe", rememberMe);

      let fetchParams = {
        method: "POST",
        body: data
      };

      let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/auth/signup/local`, fetchParams)
      let resJson = await res.json();
      if (res.ok) {
        console.log(`Logged in.`);
        dispatch(setAccessToken(resJson.token));
        dispatch(setUser(resJson.user));
        ReactGA.set({ userId: resJson.user.id });
        dispatch(hideLoginDialog());
        return resJson.user;
      } else {
        dispatch(setAccessToken(null));
        dispatch(setUser(null));
        dispatch(setLoginError(resJson.message));
      }
    } catch (err) {
      console.error(`Signup error: ${err}`);
      dispatch(setLoginError('Failed to sign up'));
    } finally {
      dispatch(setIsLoggingIn(false));
    }
  }
}

export function logout() {
  return async (dispatch, getState) => {
    try {
      dispatch(setUser(null));

      let auth = getState().auth;
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${auth.accessToken}`);

      let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/auth/logout`, {
        method: 'GET',
        credentials: 'same-origin',
        headers,
      });
      dispatch(setAccessToken(null));
      if (res.ok) {
        console.log("Successfull logout");
      }
      if (res.status === 401) {
        console.log("Not authorized to logout");
      }
    } catch (err) {
      console.log(`Logout error ${err}`);
    }
  }
}

export function setIsLoggingIn(isLoggingIn) {
  return {
    type: AUTH_SET_IS_LOGGING_IN,
    isLoggingIn
  }
}

export function setAccessToken(accessToken) {
  return {
    type: AUTH_SET_ACCESS_TOKEN,
    accessToken
  }
}

export function setUser(user) {
  return {
    type: AUTH_SET_USER,
    user
  }
}

export function setLoginError(loginError) {
  return {
    type: AUTH_SET_LOGIN_ERROR,
    loginError
  }
}

export function initialize() {
  return async (dispatch) => {
    try {
      var myHeaders = new Headers();
      var myInit = {
        method: 'GET',
        headers: myHeaders,
        credentials: 'same-origin'
      };

      let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/auth/renew`, myInit);
      let resJson = await res.json();

      if (res.ok) {
        console.log(`Logged in.`);
        dispatch(setAccessToken(resJson.token));
        dispatch(setUser(resJson.user));
        ReactGA.set({ userId: resJson.user.id });
      } else {
        dispatch(setAccessToken(null));
        dispatch(setUser(null));
      }
    } catch (err) {
      console.log(`Not logged in because: ${err.status}`);
    } finally {
      dispatch(setIsInitialized(true));
    }
  }
}

export function setIsInitialized(isInitialized) {
  return {
    type: AUTH_SET_IS_INITIALIZED,
    isInitialized
  }
}

export function openLoginDialog() {
  return (dispatch) => {
    dispatch(setIsLoginDialogOpen(true));
  }
}

export function hideLoginDialog() {
  return (dispatch) => {
    dispatch(setIsLoginDialogOpen(false));
  }
}

export function setIsLoginDialogOpen(isLoginDialogOpen) {
  return {
    type: AUTH_SET_IS_LOGIN_DIALOG_OPEN,
    isLoginDialogOpen
  }
}
