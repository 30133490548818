import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom'
import Loadable from 'react-loadable';

import './index.css'
import NavBar from '../components/NavBar/Navbar'
import MapStripe from './MapStripe'
import Footer from "../components/mobileFooter/footer";
import ReactGA from "react-ga4";

class LandingPage extends Component {

  onMouseOver() {
    Loadable.preloadAll();
  }

  render() {
    return (
      <div>
        <div className="sticky-top mobile-main-navbar">
          <NavBar/>
        </div>
        <div className="mobile-main-landing">

          <div id="mobile-claim-group">
            <div id="mobile-big-claim">
              Free<br/>
              Tent Camping
            </div>
            <div id="mobile-sub-claim">
              Find free, car accessible, private camping away from the crowds<br/>
            </div>
            <Link to="/app" onClick={() => ReactGA.event({category: "General", action: 'Browse Spots'})}>
              <button id="mobile-action-button" onMouseOver={this.onMouseOver}>
                Browse Spots
              </button>
            </Link>
          </div>
        </div>
        <div className="mobile-map-landing-page">
          <MapStripe className="map"/>
        </div>
        <div className='mobile-about'>
          <div>
            Tent Spots provide easy access to browse thousands of spots nearby. Explore the best free camping on your travels. Make the right choice with 360 degree panorama campground viewer. Check the surroundings with drone powered High Resolution maps.            {/*We are commited to mapping every little corner of the earth where you can put up a tent. This comes from us and many of our friends struggling to find a camping spot when travelling or eve during summer rush hours. Earth is a big place and there is always a tent spot for everyone*/}
          </div>
        </div>
        <div id='mobile-tiles'>
          <div className="mobile-tile">
            <img src="/images/Drinking-water.png" alt="Drinking water"/>
            <div>DRINKING<br/>WATER</div>
          </div>
          <div className="mobile-tile">
            <img src="/images/Car-accessible.png" alt="Car accessible"/>
            <div>CAR<br/>ACCESSIBLE</div>
          </div>
          <div className="mobile-tile">
            <img src="/images/Swimming-water.png" alt="Swimming water"/>
            <div>SWIMMING<br/>WATER</div>
          </div>
          <div className="mobile-tile">
            <img src="/images/Fire-pit.png" alt="Fire pit"/>
            <div>FIRE<br/>PIT</div>
          </div>
          <div className="mobile-tile">
            <img src="/images/Scenic-view.png" alt="Scenic view"/>
            <div>SCENIC<br/>VIEW</div>
          </div>
          {/*<div className="mobile-tile">*/}
          {/*  <img src="/images/camp-free.png" alt="based on Camp Free book"/>*/}
          {/*  <div>CAMP<br/>FREE</div>*/}
          {/*</div>*/}
        </div>
        <Footer />
      </div>
    )
  }
}


export default withRouter(LandingPage);
