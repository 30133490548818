import {applyMiddleware, createStore} from 'redux'
import thunk from 'redux-thunk'
import { createLogger } from 'redux-logger'
import rootReducer from '../reducers/index'

export default function configureStore(initialState){
    const logger = createLogger();
    const store = createStore(
        rootReducer,
        initialState,
        applyMiddleware(logger, thunk)
    );

    return store;
}