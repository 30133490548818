import {
  TENTSPOTS_SELECTED_SPOT,
  TENTSPOTS_MAP_HOVER_SPOT,
  TENTSPOTS_HOVER_SPOT,
  TENTSPOTS_IS_NEW_SPOT_BEING_ADDED,
  TENTSPOTS_IS_LOCATION_UPDATED_BY_IMAGE,
  TENTSPOTS_IMAGE_PREVIEW,
  TENTSPOTS_ADD_NEW_SPOT_ERROR,
  TENTSPOTS_IS_LOADING,
  TENTSPOTS_ERROR,
  TENTSPOTS_ADD_CAMPS_TO_CACHE,
  TENTSPOTS_SET_IS_CAMPS_VISIBLE,
  TENTSPOTS_SET_SELECTED_CAMP,
  TENTSPOTS_ADD_SPOT_TO_CACHE,
  TENTSPOTS_ADD_SPOTS_TO_CACHE,
  SPOT_UPDATE,
  SPOT_DELETE,
  CAMP_UPDATE,
  CAMP_DELETE,
  TENTSPOTS_SET_SPOTS_CAMP,
  TENTSPOTS_SET_IS_FREE_ONLY_VISIBLE,
  REVIEW_UPDATE,
  TENTSPOTS_UPDATE_VISIBLE_SPOTS,
  TENTSPOTS_UPDATE_VISIBLE_CAMPS,
  TENTSPOTS_SET_TOTAL_SPOT_COUNT,
  TENTSPOTS_SET_TOTAL_CAMP_COUNT,
  TENTSPOTS_SET_TOTAL_CAMPS_SPOT_COUNT,
  TENTSPOTS_SET_ASPECT_RATIO

} from '../Actions/actionTypes'

const initialState = {
  cachedSpots: [],
  visibleSpots: [],
  visibleSpotsOnMap: [],
  totalSpotCount: 0,
  totalCampCount: 0,
  totalCampsSpotCount: 0,
  visibleCamps: [],
  visibleCampsOnMap: [],
  cachedCamps: [],
  isLoading: false,
  isFirstLoading: true,
  isCampsVisible: true,
  isFreeOnlyVisible: false,
  error: null,
  isNewSpotBeingAdded: false,
  isLocationUpdatedByImage: false,
  imagePreview: [],
  addNewSpotError: null,
  hoverSpot: null,
  mapHoverSpot: null,
  selectedSpot: null,
  selectedCamp: null,
  aspectRatio: null
};

function spatialFilter(bounds, possibleVisibleSpots, x, y) {
  let visibleSpots = [];
  let iStep = (bounds[1][0] - bounds[0][0]) / x;
  let jStep = (bounds[1][1] - bounds[0][1]) / y;
  for (let i = bounds[0][0]; i <= bounds[1][0]; i += iStep) {
    for (let j = bounds[0][1]; j <= bounds[1][1]; j += jStep) {
      if (possibleVisibleSpots.length > 0) {
        possibleVisibleSpots.forEach(spot => {
          spot.distance = calcCrow(spot.location[0], spot.location[1], i, j);
        });
        possibleVisibleSpots.sort(function (first, second) {
          return first.distance - second.distance
        });
        possibleVisibleSpots[0].distance = undefined;
        visibleSpots.push(possibleVisibleSpots[0]);
        possibleVisibleSpots = possibleVisibleSpots.slice(1, possibleVisibleSpots.length);
      }
    }
  }
  return visibleSpots;
}

export default function tentspots(state = initialState, action) {
  switch (action.type) {
    case TENTSPOTS_SELECTED_SPOT:
      return {
        ...state,
        selectedSpot: action.selectedSpot
      };
    case TENTSPOTS_SET_SELECTED_CAMP:
      return {
        ...state,
        selectedCamp: action.selectedCamp
      };
    case TENTSPOTS_MAP_HOVER_SPOT:
      return {
        ...state,
        mapHoverSpot: action.spot
      };
    case TENTSPOTS_HOVER_SPOT:
      return {
        ...state,
        hoverSpot: action.spot
      };
    case TENTSPOTS_IMAGE_PREVIEW:
      return {
        ...state,
        imagePreview: action.imagePreview
      };
    case TENTSPOTS_ADD_NEW_SPOT_ERROR:
      return {
        ...state,
        addNewSpotError: action.error
      };
    case TENTSPOTS_IS_NEW_SPOT_BEING_ADDED:
      return {
        ...state,
        isNewSpotBeingAdded: action.isAdding
      };
    case TENTSPOTS_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case TENTSPOTS_SET_IS_CAMPS_VISIBLE:
      return {
        ...state,
        isCampsVisible: action.isCampsVisible
      };
    case TENTSPOTS_ERROR:
      return {
        ...state,
        error: action.error
      };
    case TENTSPOTS_UPDATE_VISIBLE_SPOTS: {
      let possibleVisibleSpots = [...state.cachedSpots
        .filter(spot => state.selectedCamp === null ? spot.group === undefined || spot.group === null : true)
        .filter(spot =>
          spot.location[0] > action.bounds[0][0] && spot.location[0] < action.bounds[1][0] &&
          spot.location[1] > action.bounds[0][1] && spot.location[1] < action.bounds[1][1])];

      let visibleSpots = spatialFilter(action.bounds, possibleVisibleSpots, 6, 6);
      visibleSpots.sort((a, b) => {
        if (b.primaryImageId && !a.primaryImageId) {
          return 1
        } else if (!b.primaryImageId && a.primaryImageId) {
          return -1
        } else {
          return b.starRating - a.starRating
        }
      });

      let visibleSpotsOnMap = [...visibleSpots];
      return {
        ...state,
        visibleSpots: visibleSpots.splice(0, 10),
        visibleSpotsOnMap,
        isFirstLoading: false
      };
    }
    case TENTSPOTS_UPDATE_VISIBLE_CAMPS: {
      let candidateCamps = [...state.cachedCamps
        .filter(camp =>
          camp.location[0] > action.bounds[0][0] && camp.location[0] < action.bounds[1][0] &&
          camp.location[1] > action.bounds[0][1] && camp.location[1] < action.bounds[1][1])];

      let visibleCamps = spatialFilter(action.bounds, candidateCamps, 4, 4);
      // visibleCamps.sort((a, b) => {
      //   if (b.primaryImageId && !a.primaryImageId) {
      //     return 1
      //   } else if (!b.primaryImageId && a.primaryImageId) {
      //     return -1
      //   } else {
      //     return b.starRating - a.starRating
      //   }
      // });
      let visibleCampsOnMap = [...visibleCamps];
      return {
        ...state,
        visibleCamps: visibleCamps.splice(0, 2),
        visibleCampsOnMap,
        isFirstLoading: false
      };
    }
    case TENTSPOTS_SET_TOTAL_SPOT_COUNT: {
      return {
        ...state,
        totalSpotCount: action.totalSpotCount
      };
    }
    case TENTSPOTS_SET_TOTAL_CAMP_COUNT: {
      return {
        ...state,
        totalCampCount: action.totalCampCount
      };
    }
    case TENTSPOTS_SET_TOTAL_CAMPS_SPOT_COUNT: {
      return {
        ...state,
        totalCampsSpotCount: action.totalCampsSpotCount
      };
    }
    case TENTSPOTS_ADD_SPOTS_TO_CACHE: {
      let newCachedSpots = [...state.cachedSpots];
      action.spots.forEach(spot => {
        let cachedSpotIndex = newCachedSpots.findIndex(oldSpot => oldSpot.id === spot.id)
        if (cachedSpotIndex === -1) {
          newCachedSpots.push({...spot});
        }
        else {
          newCachedSpots[cachedSpotIndex] = {...spot};
        }
      });

      return {
        ...state,
        cachedSpots: newCachedSpots
      };
    }
    case TENTSPOTS_ADD_SPOT_TO_CACHE: {
      return {
        ...state,
        cachedSpots: [...state.cachedSpots.filter(spot => spot.id !== action.spot.id), action.spot]
      };
    }
    case TENTSPOTS_ADD_CAMPS_TO_CACHE: {
      let newCachedCamps = [...state.cachedCamps];
      action.camps.forEach(camp => {
        let cachedCampIndex = newCachedCamps.findIndex(oldCamp => oldCamp.id === camp.id)
        if (cachedCampIndex === -1) {
          newCachedCamps.push({...camp});
        }
        else {
          newCachedCamps[cachedCampIndex] = {...camp};
        }
      });

      return {
        ...state,
        cachedCamps: newCachedCamps
      };
    }
    case SPOT_UPDATE: {
      let newVisibleSpots = [{...action.spot}, ...state.visibleSpots.filter(spot => spot.id !== action.spot.id)];
      let newCachedSpots = [{...action.spot}, ...state.cachedSpots.filter(spot => spot.id !== action.spot.id)];

      return {
        ...state,
        visibleSpots: newVisibleSpots,
        cachedSpots: newCachedSpots,
        selectedSpot: (state.selectedSpot && state.selectedSpot.id === action.spot.id) ? {...action.spot} : state.selectedSpot
      }
    }
    case REVIEW_UPDATE: {
      let newSelectedSpot = {...state.selectedSpot};
      for (var i in newSelectedSpot.reviews) {
        if (newSelectedSpot.reviews[i].id === action.review.id) {
          newSelectedSpot.reviews[i] = action.review;
          break; //Stop this loop, we found it!
        }
      }
      let newVisibleSpots = [newSelectedSpot, ...state.visibleSpots.filter(spot => spot.id !== newSelectedSpot.id)];
      let newCachedSpots = [newSelectedSpot, ...state.cachedSpots.filter(spot => spot.id !== newSelectedSpot.id)];

      return {
        ...state,
        visibleSpots: newVisibleSpots,
        cachedSpots: newCachedSpots,
        selectedSpot: newSelectedSpot
      }
    }
    case SPOT_DELETE: {
      let newVisibleSpots = [...state.visibleSpots.filter(spot => spot.id !== action.spotId)];
      let newVisibleSpotsOnMap = [...state.visibleSpotsOnMap.filter(spot => spot.id !== action.spotId)];
      let newCachedSpots = [...state.cachedSpots.filter(spot => spot.id !== action.spotId)];

      return {
        ...state,
        visibleSpots: newVisibleSpots,
        visibleSpotsOnMap: newVisibleSpotsOnMap,
        cachedSpots: newCachedSpots,
        selectedSpot: (state.selectedSpot && state.selectedSpot.id !== action.spotId) ? {...state.selectedSpot} : null
      }
    }
    case CAMP_UPDATE: {
      let newVisibleCamps = [action.camp, ...state.visibleCamps.filter(camp => camp.id !== action.camp.id)];
      let newCachedCamps = [action.camp, ...state.cachedCamps.filter(camp => camp.id !== action.camp.id)];

      return {
        ...state,
        visibleCamps: newVisibleCamps,
        cachedCamps: newCachedCamps,
        selectedCamp: (state.selectedCamp && state.selectedCamp.id !== action.camp.id) ? {...state.selectedCamp} : action.camp
      }
    }
    case CAMP_DELETE: {
      // Show spots that belonged to the deleted camp
      let camp = state.cachedCamps.find(camp => camp.id === action.campId);
      let deletedSpots = state.cachedSpots
        .filter(spot => camp.spotIds.some(spotId => spotId === spot.id))
        .map(spot => {
          spot.group = undefined;
          return spot;
        });
      let newVisibleSpots = [...state.visibleSpots, ...deletedSpots];
      let newVisibleSpotsOnMap = [...state.visibleSpotsOnMap, ...deletedSpots];
      let newCachedSpots = [...state.cachedSpots.filter(spot => deletedSpots.find(deletedSpot => deletedSpot.id === spot.id)), ...deletedSpots];

      let newVisibleCamps = [...state.visibleCamps.filter(camp => camp.id !== action.campId)];
      let newVisibleCampsOnMap = [...state.visibleCampsOnMap.filter(camp => camp.id !== action.campId)];
      let newCachedCamps = [...state.cachedCamps.filter(camp => camp.id !== action.campId)];


      return {
        ...state,
        visibleSpots: newVisibleSpots ? newVisibleSpots : state.visibleSpots,
        visibleSpotsOnMap: newVisibleSpotsOnMap,
        cachedSpots: newCachedSpots,
        visibleCamps: newVisibleCamps,
        visibleCampsOnMap: newVisibleCampsOnMap,
        cachedCamps: newCachedCamps,
        selectedCamp: (state.selectedCamp && state.selectedCamp.id !== action.campId) ? {...state.selectedCamp} : null
      }
    }
    case TENTSPOTS_SET_SPOTS_CAMP: {
      let spot = state.cachedSpots.find(i => i.id === action.spotId);
      spot.group = {id: action.campId};
      let newVisibleSpots = [...state.visibleSpots.filter(i => i.id !== action.spotId)];
      let newVisibleSpotsOnMap = [...state.visibleSpotsOnMap.filter(i => i.id !== action.spotId)];
      let newCachedSpots = [...state.cachedSpots.filter(i => i.id !== action.spotId), {...spot}];
      if (action.campId === undefined) {
        newVisibleSpots.push({...spot});
        newVisibleSpotsOnMap.push({...spot});
      }
      return {
        ...state,
        visibleSpots: newVisibleSpots,
        visibleSpotsOnMap: newVisibleSpotsOnMap,
        cachedSpots: newCachedSpots,
      }
    }
    case TENTSPOTS_IS_LOCATION_UPDATED_BY_IMAGE:
      return {
        ...state,
        isLocationUpdatedByImage: action.isLocationUpdatedByImage
      };
    case TENTSPOTS_SET_IS_FREE_ONLY_VISIBLE:
      return {
        ...state,
        isFreeOnlyVisible: action.isFreeOnlyVisible
      };
    case TENTSPOTS_SET_ASPECT_RATIO:
      return {
        ...state,
        aspectRatio: action.aspectRatio
      };



    default:
      return state;

  }


}

//This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
function calcCrow(lat1, lon1, lat2, lon2) {
  var R = 6371; // km
  var dLat = toRad(lat2 - lat1);
  var dLon = toRad(lon2 - lon1);
  lat1 = toRad(lat1);
  lat2 = toRad(lat2);

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d;
}

// Converts numeric degrees to radians
function toRad(Value) {
  return Value * Math.PI / 180;
}
