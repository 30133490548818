import {
  TENTSPOTS_SELECTED_SPOT,
  TENTSPOTS_MAP_HOVER_SPOT,
  TENTSPOTS_HOVER_SPOT,
  TENTSPOTS_IS_NEW_SPOT_BEING_ADDED,
  TENTSPOTS_IS_LOCATION_UPDATED_BY_IMAGE,
  TENTSPOTS_IMAGE_PREVIEW,
  TENTSPOTS_ADD_NEW_SPOT_ERROR,
  TENTSPOTS_IS_LOADING,
  TENTSPOTS_ERROR,
  TENTSPOTS_ADD_CAMPS_TO_CACHE,
  TENTSPOTS_SET_IS_CAMPS_VISIBLE,
  TENTSPOTS_SET_SELECTED_CAMP,
  TENTSPOTS_ADD_SPOT_TO_CACHE,
  TENTSPOTS_ADD_SPOTS_TO_CACHE,
  TENTSPOTS_SET_SPOTS_CAMP,
  TENTSPOTS_SET_IS_FREE_ONLY_VISIBLE,
  TENTSPOTS_UPDATE_VISIBLE_SPOTS,
  TENTSPOTS_UPDATE_VISIBLE_CAMPS,
  TENTSPOTS_SET_TOTAL_SPOT_COUNT,
  TENTSPOTS_SET_TOTAL_CAMP_COUNT,
  TENTSPOTS_SET_TOTAL_CAMPS_SPOT_COUNT,
  TENTSPOTS_SET_ASPECT_RATIO
} from './actionTypes'
import {
  setBounds,
  setUserSet,
  setIsAddingLocation,
  setAddingLocation,
  initializeViewPort,
  setIpLocation,
  setMapType
} from './map'
import history from "../Common/history";
import {setError} from "./admin";
import ReactGA from "react-ga4";




export function loadSpot(spotId) {
  return (dispatch) => {
    return fetch(`${process.env.REACT_APP_API_ADDRESS}/api/spots/${spotId}`)
      .then(res => res.json())
      .then(spot => {
        if (spot.group) {
          dispatch(setSelectedCampId(spot.group.id));
        } else {
          dispatch(tentspotsLoadAreaAroundPoint(spot.location));
        }
        dispatch(tentspotsAddSpotToCache(spot));
        return Promise.resolve(spot);
      })
      .catch((err) => {
        console.log(err);
        dispatch(tentspotsGenericError());
      });
  }
}

export function loadSpots(spotIds) {
  return async (dispatch) => {
    let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/spots?ids=${JSON.stringify(spotIds)}`);
    if (!res.ok) {
      return console.log(`Failed to load spots`);
    }

    let resJson = await res.json();
    resJson.spots.forEach(spot => {
      dispatch(tentspotsAddSpotToCache(spot));
    });
    return Promise.resolve();
  }
}

export function loadSelectedSpot(spotId) {
  return (dispatch) => {
    dispatch(loadSpot(spotId))
      .then(spot => {
        dispatch(setSelectedSpot(spot));
      })
      .catch((err) => {
        console.log(err);
        dispatch(tentspotsGenericError());
      });
  }
}

export function setSelectedSpotId(spotId) {
  return (dispatch, getState) => {
    let state = getState().tentspots;
    if (!(state.selectedSpot && state.selectedSpot.id === spotId)) {
      let selectedSpot = state.cachedSpots.find(spot => spot.id === spotId);
      if (selectedSpot) {
        dispatch(setSelectedSpot(selectedSpot));
      } else {
        dispatch(loadSelectedSpot(spotId));
      }
    }
  }
}

export function setSelectedSpot(selectedSpot) {
  return {
    type: TENTSPOTS_SELECTED_SPOT,
    selectedSpot
  }
}

export function loadSelectedCamp(campIdName) {
  return async (dispatch, getState) => {
    let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/camps?idName=${campIdName}`)
    if (!res.ok) {
      return dispatch(setError(`Camp ${campIdName} not found`));
    }

    let camp = (await res.json()).camp;
    dispatch(setSelectedCamp(camp));
    let state = getState().tentspots;
    let spotsToLoad = camp.spotIds.filter(spotId => !state.cachedSpots.find(i => i.id === spotId));
    dispatch(loadSpots(spotsToLoad));

    dispatch(setUserSet(false));
    if (camp.mapView.southwestBound[0] !== null) {
      dispatch(setBounds([camp.mapView.southwestBound, camp.mapView.northeastBound]));
    } else {
      let lowerLeftBound = [camp.location[0] - 0.005, camp.location[1] - 0.005];
      let upperRightBound = [camp.location[0] + 0.005, camp.location[1] + 0.005];
      let newBounds = [lowerLeftBound, upperRightBound];
      dispatch(setBounds(newBounds));
    }
  }
}

export function setSelectedCampId(campIdName) {
  return (dispatch, getState) => {
    let state = getState().tentspots;
    if (!(state.selectedCamp && state.selectedCamp.idName === campIdName)) {
      let selectedCamp = state.cachedCamps.find(camp => camp.idName === campIdName);
      if (selectedCamp) {
        dispatch(setSelectedCamp(selectedCamp));
        let spotsToLoad = selectedCamp.spotIds.filter(spotId => !state.cachedSpots.find(i => i.id === spotId));
        if (spotsToLoad.length !== 0) {
          dispatch(loadSpots(spotsToLoad));
        }

        dispatch(setUserSet(false));
        if (selectedCamp.mapView.southwestBound[0] !== null) {
          dispatch(setBounds([selectedCamp.mapView.southwestBound, selectedCamp.mapView.northeastBound]));
        } else {
          let lowerLeftBound = [selectedCamp.location[0] - 0.005, selectedCamp.location[1] - 0.005];
          let upperRightBound = [selectedCamp.location[0] + 0.005, selectedCamp.location[1] + 0.005];
          let newBounds = [lowerLeftBound, upperRightBound];
          dispatch(setBounds(newBounds));
        }
      } else {
        dispatch(loadSelectedCamp(campIdName));
      }
    }
  }
}

export function setSelectedCamp(selectedCamp) {
  return (dispatch) => {
    if (selectedCamp !== null) {
      dispatch(setMapType('hybrid'));
    }
    dispatch(setSelectedCampActual(selectedCamp));
  }
}

export function setSelectedCampActual(selectedCamp) {
  return {
    type: TENTSPOTS_SET_SELECTED_CAMP,
    selectedCamp
  }
}

export function setMapHoverSpot(spot) {
  return {
    type: TENTSPOTS_MAP_HOVER_SPOT,
    spot
  }
}

export function setHoverSpot(spot) {
  return {
    type: TENTSPOTS_HOVER_SPOT,
    spot
  }
}

export function addNewSpot(spot) {
  return async (dispatch, getState) => {
    try {
      dispatch(addNewSpotError());
      dispatch(isNewSpotBeingAdded(true));

      let body = new FormData();
      body.append("location", JSON.stringify(spot.location));
      spot.images.forEach(image => body.append("media", image));
      body.append("isCarAccessible", spot.amenities.isCarAccessible);
      body.append("isSwimmingWater", spot.amenities.isSwimmingWater);
      body.append("isDrinkingWater", spot.amenities.isDrinkingWater);
      body.append("isFirePit", spot.amenities.isFirePit);
      body.append("isScenicView", spot.amenities.isScenicView);
      body.append("isCampFree", spot.amenities.isCampFree);
      body.append("starRating", spot.starRating);
      body.append("name", spot.name);
      body.append("text", spot.text);

      let auth = getState().auth;
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${auth.accessToken}`);

      let fetchParams = {
        method: "POST",
        body,
        headers
      };

      let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/spots`, fetchParams);
      if (!res.ok) {
        return dispatch(addNewSpotError((await res.json()).message));
      }
      let newSpot = (await res.json()).spot;
      console.log(newSpot);
      dispatch(tentspotsAddSpotToCache(newSpot));
      return newSpot;
    } catch (err) {
      dispatch(addNewSpotError(err));
    } finally {
      dispatch(isNewSpotBeingAdded(false));
    }
  }
}

export function sendImage(image) {
  return async (dispatch, getState) => {
    let auth = getState().auth;
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${auth.accessToken}`);
    const body = new FormData();
    body.append("media", image);
    let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/medias`, {
      method: 'POST',
      credentials: 'same-origin',
      headers,
      body,
    });

    if (res.ok) {
      var jsonRes = await res.json();
      console.log(`Image sent: {id: ${jsonRes.media.id}}`);
      return jsonRes.media;
    }
  }
}

export function addNewCamp(camp) {
  return async (dispatch, getState) => {
    try {
      dispatch(isNewSpotBeingAdded(true));

      let imageIds = await Promise.all(camp.images.map(image => dispatch(sendImage(image))));

      let body = new FormData();
      body.append("location", JSON.stringify(camp.location));
      body.append("spots", JSON.stringify(camp.spots));
      body.append("name", camp.tittle);
      body.append("description", camp.description);
      body.append("isCarAccessible", camp.isCarAccessible);
      body.append("isSwimmingWater", camp.isSwimmingWater);
      body.append("isDrinkingWaterAvailable", camp.isDrinkingWaterAvailable);
      body.append("isFirePit", camp.isFirePit);
      body.append("isScenicView", camp.isScenicView);
      body.append("mapViewBoundNortheast", JSON.stringify(camp.mapViewBoundNortheast));
      body.append("mapViewBoundSouthwest", JSON.stringify(camp.mapViewBoundSouthwest));
      if (imageIds[0]) {
        body.append("primaryImage1", imageIds[0].id);
      }
      if (imageIds[1]) {
        body.append("primaryImage2", imageIds[1].id);
      }
      if (imageIds[2]) {
        body.append("primaryImage3", imageIds[2].id);
      }
      let auth = getState().auth;
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${auth.accessToken}`);

      let fetchParams = {
        method: "POST",
        body,
        headers
      };

      let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/camps`, fetchParams);
      if (!res.ok) {
        dispatch(addNewSpotError("Failed to add camp. Please try again later"));
        return;
      }
      let newCamp = (await res.json()).camp;
      dispatch(isNewSpotBeingAdded(false));
      history.push(`/app/campgrounds/${newCamp.idName}`);
    } catch (err) {
      dispatch(addNewSpotError(err));
    } finally {
      dispatch(isNewSpotBeingAdded(false));
    }
  }
}

export function isNewSpotBeingAdded(isAdding) {
  return {
    type: TENTSPOTS_IS_NEW_SPOT_BEING_ADDED,
    isAdding
  }
}

export function setImagePreview(imagePreview) {
  return {
    type: TENTSPOTS_IMAGE_PREVIEW,
    imagePreview
  }
}

export function addNewSpotError(error) {
  return {
    type: TENTSPOTS_ADD_NEW_SPOT_ERROR,
    error
  }
}

export function tentspotsLoadAreaAroundPoint(point) {
  return async (dispatch, getState) => {
    try {
      dispatch(tentspotsIsLoading(true));
      let tentspots = getState().tentspots;
      let freeOnly = tentspots.isFreeOnlyVisible;
      let noCamps = !tentspots.isCampsVisible;
      let aspectRatio = tentspots.aspectRatio;
      console.log(`Asking for spots near [${JSON.stringify(point)}] with aspectratio: ${aspectRatio}, freeOnly: ${freeOnly}, noCamps: ${noCamps}`);
      let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/spots/near?freeOnly=${freeOnly}&aspectRatio=${aspectRatio}&noCamps=${noCamps}${(point) ? `&location=${JSON.stringify(point)}` : ""}`)
      let response = await res.json();
      dispatch(setIpLocation(response.location));
      dispatch(setUserSet(false));
      dispatch(setBounds(response.bounds));
      dispatch(setTotalSpotCount(response.spotsTotal));
      dispatch(tentspotsAddSpotsToCache(response.spots));
      dispatch(updateVisibleSpots(response.bounds));
      if (!noCamps) {
        dispatch(setTotalCampCount(response.campsTotal));
        dispatch(setTotalCampsSpotCount(response.campsSpotsTotal));
        dispatch(tentspotsAddCampsToCache(response.camps));
      }

      dispatch(updateVisibleCamps(response.bounds));
      dispatch(tentspotsIsLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(tentspotsGenericError())
    }
  }
}

export function tentspotsLoadAreaNearMe() {
  return (dispatch) => {
    dispatch(tentspotsLoadAreaAroundPoint());
  }
}

export function tentspotsInitializeAddingPoint() {
  return async (dispatch, getState) => {
    await dispatch(tentspotsLoadAreaAroundPoint());
    let bounds = getState().map.bounds;
    let middle = [(bounds[0][0] + bounds[1][0]) / 2, (bounds[0][1] + bounds[1][1]) / 2];
    dispatch(setAddingLocation(middle));
    await dispatch(initializeViewPort());
    dispatch(setIsAddingLocation(true));
  }
}

export function tentspotsLoadArea(area) {
  return (dispatch, getState) => {
    dispatch(tentspotsIsLoading(true));

    let freeOnly = getState().tentspots.isFreeOnlyVisible;
    let noCamps = !getState().tentspots.isCampsVisible;
    let areaParams = `?location=${JSON.stringify(area)}`;
    fetch(`${process.env.REACT_APP_API_ADDRESS}/api/spots${areaParams}&freeOnly=${freeOnly}&noCamps=${noCamps}`)
      .then(res => res.json())
      .then(response => {
        dispatch(tentspotsIsLoading(false));
        dispatch(setTotalSpotCount(response.spotsTotal));
        dispatch(tentspotsAddSpotsToCache(response.spots));
        dispatch(updateVisibleSpots(getState().map.bounds));
        if (!noCamps) {
          dispatch(setTotalCampCount(response.campsTotal));
          dispatch(setTotalCampsSpotCount(response.campsSpotsTotal));
          dispatch(tentspotsAddCampsToCache(response.camps));
        }
        dispatch(updateVisibleCamps(getState().map.bounds));

        //dispatch(tentspotsError(null));
      })
      .catch((err) => {
        console.log(err);
        dispatch(tentspotsGenericError())
      });
  }
}


export function tentspotsIsLoading(isLoading) {
  return {
    type: TENTSPOTS_IS_LOADING,
    isLoading
  }
}

export function tentspotsError(error) {
  return {
    type: TENTSPOTS_ERROR,
    error
  }
}

export function tentspotsGenericError() {
  return (dispatch) => {
    ReactGA.exception({
      description: 'Generic error',
      fatal: true
    });
    dispatch(tentspotsError("Something is not working on our end. :(  Please try again later"));
  }
}

export function updateVisibleSpots(bounds) {
  return {
    type: TENTSPOTS_UPDATE_VISIBLE_SPOTS,
    bounds
  }
}

export function updateVisibleCamps(bounds) {
  return {
    type: TENTSPOTS_UPDATE_VISIBLE_CAMPS,
    bounds
  }
}

function tentspotsAddCampsToCache(camps) {
  return {
    type: TENTSPOTS_ADD_CAMPS_TO_CACHE,
    camps
  }
}

export function tentspotsSetIsCampsVisible(isCampsVisible) {
  return {
    type: TENTSPOTS_SET_IS_CAMPS_VISIBLE,
    isCampsVisible
  }
}

export function tentspotsSetFreeOnlyVisible(isFreeOnlyVisible) {
  return {
    type: TENTSPOTS_SET_IS_FREE_ONLY_VISIBLE,
    isFreeOnlyVisible
  }
}

export function tentspotsAddSpotToCache(spot) {
  return {
    type: TENTSPOTS_ADD_SPOT_TO_CACHE,
    spot
  }
}

export function tentspotsAddSpotsToCache(spots) {
  return {
    type: TENTSPOTS_ADD_SPOTS_TO_CACHE,
    spots
  }
}

export function tentSpotsSetSpotsCamp(spotId, campId) {
  return {
    type: TENTSPOTS_SET_SPOTS_CAMP,
    spotId,
    campId
  }
}

export function setIsLocationUpdatedByImage(isLocationUpdatedByImage) {
  return {
    type: TENTSPOTS_IS_LOCATION_UPDATED_BY_IMAGE,
    isLocationUpdatedByImage
  }
}


export function setTotalSpotCount(totalSpotCount) {
  return {
    type: TENTSPOTS_SET_TOTAL_SPOT_COUNT,
    totalSpotCount
  }
}

export function setTotalCampCount(totalCampCount) {
  return {
    type: TENTSPOTS_SET_TOTAL_CAMP_COUNT,
    totalCampCount
  }
}

export function setTotalCampsSpotCount(totalCampsSpotCount) {
  return {
    type: TENTSPOTS_SET_TOTAL_CAMPS_SPOT_COUNT,
    totalCampsSpotCount
  }
}

export function setAspectRatio(aspectRatio) {
  return {
    type: TENTSPOTS_SET_ASPECT_RATIO,
    aspectRatio
  }
}
