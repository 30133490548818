import React, {Component} from 'react';
import {withRouter, Link} from 'react-router-dom'
import Loadable from 'react-loadable';

import './index.css'
import NavBar from '../components/NavBar/Navbar'
import MapStripe from './MapStripe'
import {
  tentspotsLoadAreaNearMe,
} from "../Actions/tentspots";
import {connect} from "react-redux";

import Footer from "../components/footer/footer"
import ReactGA from "react-ga4";

class LandingPage extends Component {

  componentDidMount() {
    // setTimeout(() => {
    //   Loadable.preloadAll();
    //   this.props.tentspotsLoadAreaNearMe();
    // }, 3000);
  }

  onMouseOver() {
    Loadable.preloadAll();
  }

  render() {
    return (
      <div className="main-container d-flex flex-column">
        <div className="main-landing d-flex">
          <div className="col-7">
            <NavBar/>
            <div className="claim-group d-flex flex-column">
              <div id="big-claim">
                Free<br/>
                Tent Camping
              </div>
              <div id="sub-claim">
                Find free, car accessible, private camping away from the crowds<br/>
              </div>
              <div className="d-flex flex-column align-self-start flex-shrink-1">
                <Link to="/app" onClick={() => ReactGA.event({category: "General", action: 'Browse Spots'})}>
                  <button id="browse-spots-button" onMouseOver={this.onMouseOver}>
                    Browse Spots
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-4 main-right">
            <MapStripe className="map"/>
          </div>
        </div>
        <div className='second-main d-flex'>
          <div className='about card offset-3 col-6'>
            <div>
              Tentspots provide easy access to search hundreds of hidden spots near you. Most of the sites are secluded
              dispersed camping. Check for a Fire ring, water access and whether you need to walk from a car.
              Search for any camping sites near you. Understand site layout by zooming in on the drone powered High
              resolution map. Make the right choice by looking at 360 VR picture of the tent site.
              {/*We are commited to mapping every little corner of the earth where you can put up a tent. This comes from us and many of our friends struggling to find a camping spot when travelling or eve during summer rush hours. Earth is a big place and there is always a tent spot for everyone*/}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {
    tentspotsLoadAreaNearMe: () => dispatch(tentspotsLoadAreaNearMe()),
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LandingPage))
