import {
  MAP_SET_BOUNDS,
  MAP_SET_USERSET,
  MAP_SET_MY_LOCATION,
  MAP_SET_IP_LOCATION,
  MAP_SET_IS_ADDING_LOCATION,
  MAP_SET_ADDING_LOCATION,
  MAP_SET_SELECTED_SPOTS_ID,
  MAP_SET_IS_SELECTING_SPOTS_ID,
  MAP_SET_BOTTOM_LEFT_VIEW_PORT,
  MAP_SET_TOP_RIGHT_VIEW_PORT,
  MAP_SET_TYPE
} from '../Actions/actionTypes'

const initialState = {
  bounds: null,
  mapType: 'roadmap',
  userSet: false,
  myLocation: null,
  ipLocation: [],
  isAddingLocation: false,
  addingLocation: [],
  isSelectingSpotsId: false,
  selectedSpotsId: [],
  bottomLeftViewPort: [],
  topRightViewPort: [],
};

export default function map(state = initialState, action) {
  switch (action.type) {
    case MAP_SET_TYPE:
      return {
        ...state,
        mapType: action.mapType
      };
    case MAP_SET_BOTTOM_LEFT_VIEW_PORT:
      return {
        ...state,
        bottomLeftViewPort: action.bottomLeftViewPort
      };
    case MAP_SET_TOP_RIGHT_VIEW_PORT:
      return {
        ...state,
        topRightViewPort: action.topRightViewPort
      };
    case MAP_SET_MY_LOCATION:
      return {
        ...state,
        myLocation: action.myLocation
      };
    case MAP_SET_IP_LOCATION:
      return {
        ...state,
        ipLocation: action.ipLocation
      };
    case MAP_SET_BOUNDS:
      return {
        ...state,
        bounds: action.bounds
      };
    case MAP_SET_USERSET:
      return {
        ...state,
        userSet: action.userSet
      };
    case MAP_SET_IS_ADDING_LOCATION:
      return {
        ...state,
        isAddingLocation: action.isAddingLocation
      };
    case MAP_SET_ADDING_LOCATION:
      return {
        ...state,
        addingLocation: action.addingLocation
      };
    case MAP_SET_SELECTED_SPOTS_ID:
      return {
        ...state,
        selectedSpotsId: action.selectedSpotsId
      };
    case MAP_SET_IS_SELECTING_SPOTS_ID:
      return {
        ...state,
        isSelectingSpotsId: action.isSelectingSpotsId
      };
    default:
      return state;
  }
}
