import {
  ADMIN_SET_STATISTICS,
  ADMIN_SET_USERS,
  ADMIN_SET_USER,
  ADMIN_SET_DELETE_USER,
  ADMIN_SET_UPDATING_USER,
  ADMIN_SET_IS_LOADING,
  ADMIN_SET_ERROR
}
  from '../Actions/actionTypes'

const initialState = {
  isLoading: false,
  error: null,
  statistics: {},
  users: [],
  updatingUserId: null
};

export default function map(state = initialState, action) {
  switch (action.type) {
    case ADMIN_SET_STATISTICS:
      return {
        ...state,
        statistics: action.statistics
      };
    case ADMIN_SET_USERS:
      return {
        ...state,
        users: action.users
      };
    case ADMIN_SET_USER: {
      let newUsers = [...state.users];
      let userIndex = newUsers.findIndex(user => user.id === action.user.id);

      newUsers[userIndex] = action.user;
      return {
        ...state,
        users: newUsers
      }
    }
    case ADMIN_SET_DELETE_USER: {
      let newUsers = [...state.users.filter(user => user.id !== action.userId)];
      return {
        ...state,
        users: newUsers
      }
    }
    case ADMIN_SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading
      };
    case ADMIN_SET_UPDATING_USER:
      return {
        ...state,
        updatingUserId: action.userId
      };
    case ADMIN_SET_ERROR:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
}