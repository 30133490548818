import React, {Component} from 'react';
import './App.css';
import {withRouter} from 'react-router-dom'
import {Helmet} from "react-helmet";

import DesktopLandingPage from './DesktopLandingPage'
import MobileLandingPage from './MobileLandingPage'

class App extends Component {

  render() {
    return (
      <div className="App full-height">
        <Helmet>
          <title>Tent Spots</title>
        </Helmet>
        <div className="container-fluid main">
          <div className="container-fluid d-none d-xl-block main">
            <DesktopLandingPage/>
          </div>

          <div className="container-fluid d-xl-none main">
            <MobileLandingPage/>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(App);
