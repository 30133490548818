import {
  MAP_SET_BOUNDS,
  MAP_SET_USERSET,
  MAP_SET_MY_LOCATION,
  MAP_SET_IP_LOCATION,
  MAP_SET_IS_ADDING_LOCATION,
  MAP_SET_ADDING_LOCATION,
  MAP_SET_SELECTED_SPOTS_ID,
  MAP_SET_IS_SELECTING_SPOTS_ID,
  MAP_SET_BOTTOM_LEFT_VIEW_PORT,
  MAP_SET_TOP_RIGHT_VIEW_PORT,
  MAP_SET_TYPE
} from './actionTypes'


export function initializeViewPort() {
  return (dispatch, getState) => {
    let bounds = [...getState().map.bounds];

    let height = bounds[1][0] - bounds[0][0];
    let width = bounds[1][1] - bounds[0][1];
    let bottomLeft = [bounds[0][0] + height*0.2, bounds[0][1] + width*0.2];
    let topRight = [bounds[1][0] - height*0.2, bounds[1][1] - width*0.2];
    dispatch(setBottomLeftViewPort(bottomLeft));
    dispatch(setTopRightViewPort(topRight));
  }
}

export function setMapType(mapType) {
  return {
    type: MAP_SET_TYPE,
    mapType
  }
}

export function setBottomLeftViewPort(bottomLeftViewPort) {
  return {
    type: MAP_SET_BOTTOM_LEFT_VIEW_PORT,
    bottomLeftViewPort
  }
}

export function setTopRightViewPort(topRightViewPort) {
  return {
    type: MAP_SET_TOP_RIGHT_VIEW_PORT,
    topRightViewPort
  }
}
export function setBounds(bounds) {
  return {
    type: MAP_SET_BOUNDS,
    bounds
  }
}

export function setUserSet(userSet) {
  return {
    type: MAP_SET_USERSET,
    userSet
  }
}

export function setMyLocation(myLocation) {
  return {
    type: MAP_SET_MY_LOCATION,
    myLocation
  }
}

export function getIpLocation(ipLocation) {
  return async (dispatch, getState) => {
    let auth = getState().auth;
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${auth.accessToken}`);

    let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/me/location`, {
      method: 'GET',
      credentials: 'same-origin',
      headers,
    });

    if (res.ok) {
      console.log(`Successfull ip location get`);
      var jsonRes = await res.json();
      dispatch(setIpLocation(jsonRes.location));
    }
    else {
      console.log(`IP location not found. Probably localhost. Setting default [45.460831, -122.314896]`);
      dispatch(setIpLocation([45.460831, -122.314896]));
    }
  }
}

export function setIpLocation(ipLocation) {
  return {
    type: MAP_SET_IP_LOCATION,
    ipLocation
  }
}

export function setIsAddingLocation(isAddingLocation) {
  return {
    type: MAP_SET_IS_ADDING_LOCATION,
    isAddingLocation
  }
}

export function zoomOnLocation(location) {
  return (dispatch, getState) => {
    let padding = 0.0002;
    let lowerLeftBound = [location[0] - padding, location[1] - padding];
    let upperRightBound = [location[0] + padding, location[1] + padding];
    let newBounds = [lowerLeftBound, upperRightBound];
    dispatch(setUserSet(false));
    dispatch(setBounds(newBounds));
    let mapType = getState().map.mapType;
    if (mapType !== 'hybrid') {
      dispatch(setMapType('hybrid'));
    }
  }
}

export function setAddingLocationWithBoundsUpdate(addingLocation) {
  return (dispatch, getState) => {
    let bounds = [...getState().map.bounds];
    //let middle = [(bounds[0][0] + bounds[1][0]) / 2, (bounds[0][1] + bounds[1][1]) / 2]
    bounds[0][0] = Math.min(bounds[0][0], addingLocation[0]);
    bounds[1][0] = Math.max(bounds[1][0], addingLocation[0]);
    bounds[0][1] = Math.min(bounds[0][1], addingLocation[1]);
    bounds[1][1] = Math.max(bounds[1][1], addingLocation[1]);
    dispatch(setUserSet(false));
    dispatch(setBounds(bounds));
    dispatch(setAddingLocation(addingLocation));
  }
}

export function setAddingLocation(addingLocation) {
  return {
    type: MAP_SET_ADDING_LOCATION,
    addingLocation
  }
}

export function setSelectedSpotsId(selectedSpotsId) {
  return {
    type: MAP_SET_SELECTED_SPOTS_ID,
    selectedSpotsId
  }
}

export function setIsSelectingSpotsId(isSelectingSpotsId) {
  return {
    type: MAP_SET_IS_SELECTING_SPOTS_ID,
    isSelectingSpotsId
  }
}