import {
  ADMIN_SET_STATISTICS,
  ADMIN_SET_USERS,
  ADMIN_SET_USER,
  ADMIN_SET_DELETE_USER,
  ADMIN_SET_UPDATING_USER,
  ADMIN_SET_IS_LOADING,
  ADMIN_SET_ERROR
} from './actionTypes'



export function loadStatistics() {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      let auth = getState().auth;
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${auth.accessToken}`);

      let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/statistics`, {
        method: 'GET',
        credentials: 'same-origin',
        headers,
      });

      if (res.ok) {
        console.log("Successfull got statistics");
        let statistics = await res.json();
        dispatch(setStatistics(statistics))
      } else {
        throw new Error(`No Ok response. Response status: ${res.status}`);
      }
    } catch (err) {
      console.log(`Failed to load statistics ${err}`);
      dispatch(setError(`Failed to load statistics`));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
}

export function loadUsers() {
  return async (dispatch, getState) => {
    try {
      dispatch(setIsLoading(true));
      let auth = getState().auth;
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${auth.accessToken}`);

      let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/users`, {
        method: 'GET',
        credentials: 'same-origin',
        headers,
      });

      if (res.ok) {
        console.log("Successfull got statistics");
        let users = (await res.json()).users;
        dispatch(setUsers(users))
      } else {
        throw new Error(`No Ok response. Response status: ${res.status}`);
      }
    } catch (err) {
      console.log(`Failed to load users ${err}`);
      dispatch(setError(`Failed to load users`));
    } finally {
      dispatch(setIsLoading(false));
    }
  }
}

function updateUser(userId, body) {
  return async (dispatch, getState) => {
    try {
      dispatch(setUpdatingUser(userId));
      let auth = getState().auth;
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${auth.accessToken}`);

      let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/users/${userId}`, {
        method: 'PATCH',
        credentials: 'same-origin',
        headers,
        body,
      });

      if (res.ok) {
        console.log(`Successfull user ${userId} update`);
        var jsonRes = await res.json();
        dispatch(setUser(jsonRes.user));
      }
    }
    catch (err) {
      dispatch(setError(`Failed to update user ${userId}`));
    }
    finally {
      dispatch(setUpdatingUser(null));
    }
  }
}

export function updateIsAdmin(userId, isAdmin) {
  return async (dispatch) => {
    const body = new FormData();
    body.append("isAdmin", isAdmin);

    dispatch(updateUser(userId, body));
  }
}

export function deleteUser(userId) {
  return async (dispatch, getState) => {
    try {
      dispatch(setUpdatingUser(userId));
      let auth = getState().auth;
      const headers = new Headers();
      headers.append('Authorization', `Bearer ${auth.accessToken}`);

      let res = await fetch(`${process.env.REACT_APP_API_ADDRESS}/api/users/${userId}`, {
        method: 'DELETE',
        credentials: 'same-origin',
        headers,
      });

      if (res.ok) {
        console.log(`Successfull deleted user ${userId}`);
        dispatch(setDeleteUser(userId));
      }
    }
    catch (err) {
      dispatch(setError(`Failed to delete user ${userId}`));
    }
    finally {
      dispatch(setUpdatingUser(null));
    }
  }
}

export function setStatistics(statistics) {
  return {
    type: ADMIN_SET_STATISTICS,
    statistics
  }
}

export function setUsers(users) {
  return {
    type: ADMIN_SET_USERS,
    users
  }
}

export function setUser(user) {
  return {
    type: ADMIN_SET_USER,
    user
  }
}

export function setDeleteUser(userId) {
  return {
    type: ADMIN_SET_DELETE_USER,
    userId
  }
}

export function setUpdatingUser(userId) {
  return {
    type: ADMIN_SET_UPDATING_USER,
    userId
  }
}

export function setIsLoading(isLoading) {
  return {
    type: ADMIN_SET_IS_LOADING,
    isLoading
  }
}

export function setError(error) {
  return {
    type: ADMIN_SET_ERROR,
    error
  }
}