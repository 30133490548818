import {
  default as React,
  Component,
} from "react";
import {connect} from 'react-redux'
import {withRouter, Link, matchPath} from 'react-router-dom'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faUser, faBars, faSignOutAlt, faPlus, faFolderPlus, faEdit} from '@fortawesome/free-solid-svg-icons'

import {googleLogin, logout, openLoginDialog} from '../../Actions/auth'
import './Navbar.css'
import ReactGA from "react-ga4";


class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {width: 0, height: 0};
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  }

  render() {

    const isAddingSpot = !!matchPath(
      this.props.location.pathname,
      '/app/newSpot'
    );

    return (
      <nav className="navbar navbar-expand-lg sticky-top navbar-light">
        <Link to="/" className="navbar-brand"
              onClick={() => ReactGA.event({category: "Navbar", action: 'Home logo clicked'})}>
          <img src="/images/LogoOPT.png" id="navbar-brand-landing" width={150} height={32} alt="Tent Spots"/>
        </Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                onClick={() => ReactGA.event({category: "Navbar", action: 'Toggle navbar menu'})}
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="row collapse navbar-collapse" style={{float: 'right'}} id="navbarNav">
          <ul className="navbar-nav w-100 d-flex justify-content-end">
            <li className="nav-item active">
              <Link to={`/`} onClick={() => ReactGA.event({category: "Navbar", action: 'About clicked'})}>
                <button className="btn-google-login-landing">About</button>
              </Link>
            </li>
            <li className="nav-item active">
              <Link to={`/app/newSpot`} onClick={() => ReactGA.event({category: "Navbar", action: 'Add Spot clicked'})}>
                <button className={`btn-add-spot${isAddingSpot ? ' btn-add-spot-disabled' : ''}`}>Add Spot</button>
              </Link>
            </li>
            {this.state.width < 1200 && this.props.user && this.props.user.isAdmin && this.props.selectedCamp &&
            <li className="nav-item active">
              <Link to={`/app/spots/${this.props.selectedCamp.id}/edit`}
                    onClick={() => ReactGA.event({category: "Navbar", action: 'Edit Camp clicked'})}>
                <button className="btn-google-login-landing">
                  <FontAwesomeIcon icon={faEdit} className="dropdown-icon"/>
                  Edit Camp
                </button>
              </Link>
            </li>
            }

            {this.props.isInitialized && this.props.user &&
            <div className="dropdown account-dropdown-landing-div">
              <button className="btn account-dropdown-landing dropdown-toggle" type="button" id="dropdownMenuButton"
                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                      onClick={() => ReactGA.event({category: "Navbar", action: 'User icon clicked'})}
              >
                {this.props.user.profile.picture ?
                  <img src={this.props.user.profile.picture} alt={this.props.user.profile.name}
                       className="profile-picture"/>
                  :
                  <span className='profile-picture profile-letter'
                        title={this.props.user.profile.name}>{this.props.user.profile.name[0].toUpperCase()}</span>
                }
              </button>

              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                <Link to={`/app/newSpot`} onClick={ReactGA.event({category: "Navbar", action: 'New Spot clicked'})}>
                  <div className="dropdown-item">
                    <FontAwesomeIcon icon={faPlus} className="dropdown-icon"/>
                    New Spot
                  </div>
                </Link>
                {this.props.user.isAdmin &&
                <Link to={`/app/newCamp`} onClick={() => ReactGA.event({category: "Navbar", action: 'New Camp clicked'})}>
                  <div className="dropdown-item">
                    <FontAwesomeIcon icon={faFolderPlus} className="dropdown-icon"/>
                    New Camp
                  </div>
                </Link>
                }
                <div className="dropdown-divider"/>
                <div className="dropdown-item disabled">
                  <FontAwesomeIcon icon={faUser} className="dropdown-icon"/>
                  My Spots
                </div>
                {this.props.user.isAdmin &&
                <Link to={`/admin/analytics`} onClick={() => ReactGA.event({category: "Navbar", action: 'Settings clicked'})}>
                  <div className="dropdown-item">
                    <FontAwesomeIcon icon={faBars} className="dropdown-icon"/>
                    Settings
                  </div>
                </Link>}
                <div className="dropdown-divider"/>
                <div className="dropdown-item" onClick={() => {
                  ReactGA.event({category: "Navbar", action: 'Logout clicked'})
                  this.props.logout()
                }}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="dropdown-icon"/>
                  Logout
                </div>
              </div>
            </div>}


            {this.props.isInitialized && !this.props.user &&
            <li className="nav-item active">
              <button className='btn-google-login-landing' onClick={() => this.props.openLoginDialog()}>Login</button>
            </li>
            }
          </ul>
        </div>

      </nav>)
  }
}

const mapStateToProps = (state) => {
  return {
    selectedCamp: state.tentspots.selectedCamp,
    selectedSpot: state.tentspots.selectedSpot,
    cachedCamps: state.tentspots.cachedCamps,
    user: state.auth.user,
    isInitialized: state.auth.isInitialized,
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    googleLogin: (access_token) => dispatch(googleLogin(access_token)),
    logout: () => dispatch(logout()),
    openLoginDialog: () => dispatch(openLoginDialog())
  }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navbar))
