import {
  SET_IS_SUBMITTING_REVIEW,
  SET_IS_REVIEW_SUBMITTED,
  SET_REVIEW_ERROR
} from '../Actions/actionTypes'

const initialState = {
  isSubmittingReview: false,
  isReviewSubmitted: false,
  reviewError: '',
};

export default function map(state = initialState, action) {
  switch (action.type) {

    case SET_IS_SUBMITTING_REVIEW:
      return {
        isSubmittingReview: action.isSubmittingReview
      };
    case SET_IS_REVIEW_SUBMITTED:
      return {
        isReviewSubmitted: action.isReviewSubmitted
      };
    case SET_REVIEW_ERROR:
      return {
        reviewError: action.reviewError
      };
    default:
      return state;
  }
}
