import {connect} from 'react-redux'
import {default as React, Component} from "react";
import {  withGoogleMap, GoogleMap, withScriptjs} from "react-google-maps";
import {MarkerWithLabel} from "react-google-maps/lib/components/addons/MarkerWithLabel";
import {withRouter} from 'react-router-dom'

import './index.css'
import PropTypes from "prop-types";
import history from '../../Common/history'
import ReactGA from "react-ga4";



/*
 * Sample From: https://developers.google.com/maps/documentation/javascript/examples/map-simple
 */
const MapGoogleMap = withScriptjs(withGoogleMap(props => (
  <GoogleMap
    defaultOptions={{
      // these following 7 options turn certain controls off see link below
      streetViewControl: false,
      scaleControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      panControl: false,
      zoomControl: false,
      rotateControl: false,
      fullscreenControl: false,
      draggable: false
    }}
    center={props.center}
    zoom={props.zoom}
  >
    <MarkerWithLabel position={{lat: props.spot.location[0], lng: props.spot.location[1]}}
                     onClick={(e) => props.onClick(e)}
                     icon={{
                       url: '/LandingPageMapIcon.png',
                       anchor: {
                         x: 16,
                         y: 16
                       }
                     }}
                     key="0"
                     labelAnchor={{
                       x: 153,
                       y: 165
                     }}>
      <img src="/images/StripeSpot1OPT.png" className="mobile-spot-stripe" alt="Mt. Hood camping spot" />
    </MarkerWithLabel>
  </GoogleMap>
)));

/*
 * Add <script src="https://maps.googleapis.com/maps/api/js"></script> to your HTML to provide google.maps reference
 */
class MapStripe extends Component {

  state = {
    center: {lat: 45.521242, lng: -121.778148},
    zoom: 10,
    spot: {location: [45.441242, -121.778148]}
  };

  handleSpotClick(spot) {
    ReactGA.event({category: "General", action: 'Landing Page spot clicked'})
    history.push(`/app/spots/wcyTF`)
  }

  render() {
    return (
      <MapGoogleMap
        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyAg8JX5bdbgdvjCijaKCkGch2AsF9AX1Ww&amp;language=en"
        loadingElement={<div style={{height: `100%`}}></div>}
        containerElement={
          <div style={{height: `100%`, width: `100%`, position: `absolute`}}/>
        }
        mapElement={
          <div style={{height: `100%`}}/>
        }
        onClick={() => this.handleSpotClick()}
        center={this.state.center}
        zoom={this.state.zoom}
        spot={this.state.spot}
      />
    );
  }
}

MapStripe.contextTypes = {
  router: PropTypes.object
};

const mapStateToProps = (state) => {
  return {}
};

const mapDispatchToProps = (dispatch) => {
  return {}
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MapStripe))
