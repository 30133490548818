import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import {Provider} from 'react-redux'

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import Loadable from 'react-loadable';
import registerServiceWorker from './registerServiceWorker';
import { Route, Switch, Router} from 'react-router-dom'
import history from "./Common/history";
import configureStore from './store/configureStore'

import './index.css';
import LandingPage from './LandingPage'


const store = configureStore();

function Loading(props) {
  if (props.error) {
    return <div>Error</div>;
  } else if (props.pastDelay) {
    return <div style={{width: `100%`, textAlign: 'center', marginTop: '28vh'}} >Loading TentSpots...</div>;
  } else {
    return null;
  }
}

const LoadableApp = Loadable({
  loader: () => import('./App'),
  loading: Loading,
});

const LoadableLoginDialog = Loadable({
  loader: () => import("./components/LoginDialog/LoginDialog"),
  loading: (props) => null,
});

ReactGA.initialize('G-KS38EWFX1Y');

history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname)
  }
);

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <LoadableLoginDialog />
      <Switch>
        <Route exact path="/" component={LandingPage}/>
        <Route path="/" component={LoadableApp} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root'));
registerServiceWorker();
