// auth
export const AUTH_SET_IS_LOGGING_IN = 'AUTH_SET_IS_LOGGING_IN';
export const AUTH_SET_ACCESS_TOKEN = 'AUTH_SET_ACCESS_TOKEN';
export const AUTH_SET_USER = 'AUTH_SET_USER';
export const AUTH_SET_IS_INITIALIZED = 'AUTH_SET_IS_INITIALIZED';
export const AUTH_SET_IS_LOGIN_DIALOG_OPEN = 'AUTH_SET_IS_LOGIN_DIALOG_OPEN';
export const AUTH_SET_LOGIN_ERROR = 'AUTH_SET_LOGIN_ERROR';
// map
export const MAP_SET_BOUNDS = 'MAP_SET_BOUNDS';
export const MAP_SET_USERSET = 'MAP_SET_USERSET';
export const MAP_SET_MY_LOCATION = 'MAP_SET_MY_LOCATION';
export const MAP_SET_IP_LOCATION = 'MAP_SET_IP_LOCATION';
export const MAP_SET_IS_ADDING_LOCATION = 'MAP_SET_IS_ADDING_LOCATION';
export const MAP_SET_ADDING_LOCATION = 'MAP_SET_ADDING_LOCATION';
export const MAP_SET_SELECTED_SPOTS_ID = 'MAP_SET_SELECTED_SPOTS_ID';
export const MAP_SET_IS_SELECTING_SPOTS_ID = 'MAP_SET_IS_SELECTING_SPOTS_ID';
export const MAP_SET_BOTTOM_LEFT_VIEW_PORT = 'MAP_SET_BOTTOM_LEFT_VIEW_PORT';
export const MAP_SET_TOP_RIGHT_VIEW_PORT = 'MAP_SET_TOP_RIGHT_VIEW_PORT';
export const MAP_SET_TYPE = 'MAP_SET_TYPE';
// tentspots
export const TENTSPOTS_ADD_SPOT_TO_CACHE = 'TENTSPOTS_ADD_SPOT_TO_CACHE';
export const TENTSPOTS_ADD_SPOTS_TO_CACHE = 'TENTSPOTS_ADD_SPOTS_TO_CACHE';
export const TENTSPOTS_SELECTED_SPOT = 'TENTSPOTS_SELECTED_SPOT';
export const TENTSPOTS_SET_SELECTED_CAMP = 'TENTSPOTS_SET_SELECTED_CAMP';
export const TENTSPOTS_MAP_HOVER_SPOT = 'TENTSPOTS_MAP_HOVER_SPOT';
export const TENTSPOTS_HOVER_SPOT = 'TENTSPOTS_HOVER_SPOT';
export const TENTSPOTS_IS_NEW_SPOT_BEING_ADDED = 'TENTSPOTS_IS_NEW_SPOT_BEING_ADDED';
export const TENTSPOTS_IS_LOCATION_UPDATED_BY_IMAGE = 'TENTSPOTS_IS_LOCATION_UPDATED_BY_IMAGE';
export const TENTSPOTS_IMAGE_PREVIEW = 'TENTSPOTS_IMAGE_PREVIEW';
export const TENTSPOTS_ADD_NEW_SPOT_ERROR = 'TENTSPOTS_ADD_NEW_SPOT_ERROR';
export const TENTSPOTS_IS_LOADING = 'TENTSPOTS_IS_LOADING';
export const TENTSPOTS_ERROR = 'TENTSPOTS_ERROR';
export const TENTSPOTS_ADD_CAMPS_TO_CACHE = 'TENTSPOTS_ADD_CAMPS_TO_CACHE';
export const TENTSPOTS_SET_IS_CAMPS_VISIBLE = 'TENTSPOTS_SET_IS_CAMPS_VISIBLE';
export const TENTSPOTS_SET_IS_FREE_ONLY_VISIBLE = 'TENTSPOTS_SET_IS_FREE_ONLY_VISIBLE';
export const TENTSPOTS_UPDATE_VISIBLE_SPOTS = 'TENTSPOTS_UPDATE_VISIBLE_SPOTS';
export const TENTSPOTS_UPDATE_VISIBLE_CAMPS = 'TENTSPOTS_UPDATE_VISIBLE_CAMPS';
export const TENTSPOTS_SET_TOTAL_SPOT_COUNT = 'TENTSPOTS_SET_TOTAL_SPOT_COUNT';
export const TENTSPOTS_SET_TOTAL_CAMP_COUNT = 'TENTSPOTS_SET_TOTAL_CAMP_COUNT';
export const TENTSPOTS_SET_TOTAL_CAMPS_SPOT_COUNT = 'TENTSPOTS_SET_TOTAL_CAMPS_SPOT_COUNT';
export const TENTSPOTS_SET_ASPECT_RATIO = 'TENTSPOTS_SET_ASPECT_RATIO';
// editSpot
export const SPOT_UPDATE = 'SPOT_UPDATE';
export const SPOT_DELETE = 'SPOT_DELETE';
// editCamp
export const CAMP_UPDATE = 'CAMP_UPDATE';
export const TENTSPOTS_SET_SPOTS_CAMP = 'TENTSPOTS_SET_SPOTS_CAMP';
export const CAMP_DELETE = 'CAMP_DELETE';
export const SET_IS_UPLOADING = 'SET_IS_UPLOADING';
export const SET_UPLOADING_ERROR = 'SET_UPLOADING_ERROR';
// review
export const SET_IS_SUBMITTING_REVIEW = 'SET_IS_SUBMITTING_REVIEW';
export const SET_IS_REVIEW_SUBMITTED = 'SET_IS_REVIEW_SUBMITTED';
export const SET_REVIEW_ERROR = 'SET_REVIEW_ERROR';
export const REVIEW_UPDATE = 'REVIEW_UPDATE';
//admin
export const ADMIN_SET_STATISTICS = 'ADMIN_SET_STATISTICS';
export const ADMIN_SET_USERS = 'ADMIN_SET_USERS';
export const ADMIN_SET_USER = 'ADMIN_SET_USER';
export const ADMIN_SET_DELETE_USER = 'ADMIN_SET_DELETE_USER';
export const ADMIN_SET_UPDATING_USER = 'ADMIN_SET_UPDATING_USER';
export const ADMIN_SET_IS_LOADING = 'ADMIN_SET_IS_LOADING';
export const ADMIN_SET_ERROR = 'ADMIN_SET_ERROR';
