import {combineReducers} from 'redux'
import tentspots from './tentspots'
import map from './map'
import auth from './auth'
import camp from './camp'
import review from './review'
import admin from './admin'


const rootReducer = combineReducers({
  tentspots,
  map,
  auth,
  camp,
  review,
  admin
});

export default rootReducer;