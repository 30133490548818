import React, {Component} from 'react';

import './footer.css'
import {Link} from "react-router-dom";
import ReactGA from "react-ga4";

export default class Footer extends Component {

  render() {
    return (
      <footer className="footer d-flex flex-column">
        <div className="d-flex flex-row">
          <div className="d-flex flex-row align-items-center">
            <div className="footer-header">Get the App</div>
            <a href="https://apps.apple.com/us/app/tent-spots/id1434693500?itsct=apps_box_badge&amp;itscg=30200"
               style={{
                 display: 'inline-block',
                 overflow: 'hidden',
                 borderRadius: '13px',
                 width: '155px',
                 height: '49px'
               }}
               onClick={() => {
                 ReactGA.event({category: "Footer", action: 'Get ios app'});
                 ReactGA.outboundLink({label: 'https://apps.apple.com/us/app/tent-spots/id1434693500?itsct=apps_box_badge&amp;itscg=30200'});}}>
              <img
                src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1592265600&h=7f2221616fd31031e9f55dd6c67e6ba4"
                alt="Download on the App Store" style={{borderRadius: '13px', width: '155px', height: '49px'}}/>
            </a>
            <a
              href='https://play.google.com/store/apps/details?id=com.dariuskocar.tentspots&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img
              alt='Get it on Google Play'
              src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'
              style={{height: 72, margin: 0}}
              onClick={() => {
                ReactGA.event({category: "Footer", action: 'Get android app'});
                ReactGA.outboundLink({label: 'https://play.google.com/store/apps/details?id=com.dariuskocar.tentspots&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'});
              }}/></a>
          </div>
        </div>
        <hr className="footer-hr"/>
        <div className="d-flex flex-row justify-content-between">
          <div>©2021 TentSpots · <Link to="/privacy-policy" onClick={() => ReactGA.event({
            category: "Footer",
            action: 'Privacy clicked'
          })}>Privacy</Link></div>
          <div><a href="https://twitter.com/TentSpots"
                  onClick={() => ReactGA.event({category: "Footer", action: 'Twitter clicked'})}>Twitter</a> | <a
            href="https://www.youtube.com/channel/UCT4acmCwXzybF0CV5oXUZ3g"
            onClick={() => ReactGA.event({category: "Footer", action: 'Youtube clicked'})}>Youtube</a></div>
        </div>
      </footer>
    )
  }
}
