import {
  AUTH_SET_IS_LOGGING_IN,
  AUTH_SET_ACCESS_TOKEN,
  AUTH_SET_USER,
  AUTH_SET_IS_INITIALIZED,
  AUTH_SET_IS_LOGIN_DIALOG_OPEN,
  AUTH_SET_LOGIN_ERROR} from '../Actions/actionTypes'

const initialState = {
  user: null,
  accessToken: null,
  isLoggingIn: false,
  isInitialized: false,
  isLoginDialogOpen: false,
  loginError: null
};

export default function map(state = initialState, action) {
  switch (action.type) {
    case AUTH_SET_USER:
      return {
        ...state,
        user: action.user
      };
    case AUTH_SET_LOGIN_ERROR:
      return {
        ...state,
        loginError: action.loginError
      };
    case AUTH_SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken
      };
    case AUTH_SET_IS_LOGGING_IN:
      return {
        ...state,
        isLoggingIn: action.isLoggingIn
      };
    case AUTH_SET_IS_INITIALIZED:
      return {
        ...state,
        isInitialized: action.isInitialized
      };
    case AUTH_SET_IS_LOGIN_DIALOG_OPEN:
      return {
        ...state,
        isLoginDialogOpen: action.isLoginDialogOpen
      };
    default:
      return state;
  }
}